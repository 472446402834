/*
 * Progress bar - an animated green line on a grey background
 * This can be a stand-alone element or applied to a .box
 */

.page-progress-border {
	overflow: hidden;
	position: relative;

	// These won't apply when used on a .box
	&:empty {
		height: 0.25rem;
		border-radius: 0.25rem;
	}

	&:before,
	&:after {
		left: 0;
		bottom: 0;
		content: '';
		width: 100%;
		display: block;
		height: 0.25rem;
		position: absolute;
		background: $grey-20;
		border-radius: 0.25rem;
	}

	&:after {
		background: $green;
		transform-origin: top left;
		transform: scaleX(var(--page-progress-start, 0));
		animation: 1s var(--page-progress-delay, 0s) ease-in-out forwards pageProgress;
	}

	@keyframes pageProgress {
		100% {
			transform: scaleX(min(1, var(--page-progress-end, 0)));
		}
	}
}